import React, { useEffect, useRef, useState } from 'react';
import { CandidateProfileTopSkills } from 'components/candidates/profile/top-skills/CandidateProfileTopSkills';
import { CandidateProfileImage } from 'components/candidates/profile/profile-image/CandidateProfileImage';
import { Workshifts } from 'components/timezones/Workshifts';
import moment from 'moment-timezone';
import { CandidatePublicProfileRepos } from './CandidatePublicProfileRepos';
import { Icon } from './Icon';
import AnchorIcons from './AnchorIcons';
import CandidatePublicProfileShortBio from './CandidatePublicProfileShortBio';
import { isMobile } from 'shared/utils';

function CandidatePublicProfileBio(props) {
	const [isIntersecting, setIsIntersecting] = useState(true);
	const [activeSection, setActiveSection] = useState();

	const bioRef = useRef(null);
	const shortBioRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsIntersecting(entry.isIntersecting);
			},
			{ rootMargin: isMobile() ? '-132px' : '-80px' }
		);
		observer.observe(bioRef.current);

		return () => observer.disconnect();
	}, []);

	useEffect(() => {
		const observerSections = new IntersectionObserver(
			entries => {
				const visibleSection = entries.find(
					entry => entry.isIntersecting
				)?.target;
				visibleSection && setActiveSection(visibleSection.id);
			},
			{ rootMargin: '-40% 0px -60% 0px' }
		);

		const experienceSection = document.getElementById('experience');
		const scorecardsSection = document.getElementById('scorecards');
		const technicalTestsSection = document.getElementById('technical-tests');
		const educationSection = document.getElementById('education');
		const certificationsSection = document.getElementById('certifications');

		experienceSection && observerSections.observe(experienceSection);
		scorecardsSection && observerSections.observe(scorecardsSection);
		technicalTestsSection && observerSections.observe(technicalTestsSection);
		educationSection && observerSections.observe(educationSection);
		certificationsSection && observerSections.observe(certificationsSection);

		return () => observerSections.disconnect();
	}, []);

	useEffect(() => {
		if (isIntersecting) {
			shortBioRef.current.classList.remove('visible');
		} else {
			shortBioRef.current.classList.add('visible');
		}
	}, [isIntersecting]);

	const handleAnchorClick = (
		e,
		sectionId,
		scrollOffset = isMobile() ? 132 : 80
	) => {
		e.preventDefault();
		if (activeSection !== sectionId) {
			setActiveSection(sectionId);
		}

		const yOffset = scrollOffset;

		const section = document.getElementById(sectionId);

		const y =
			section.getBoundingClientRect().top + document.body.scrollTop - yOffset;

		setIsIntersecting(false);

		setTimeout(() => {
			document.body.scrollTo({
				top: y,
				behavior: 'smooth'
			});
		}, 100);
	};

	const renderCandidateWorkshift = () => {
		if (
			!(
				props.candidate.preferredWorkshifts &&
				props.candidate.preferredWorkshifts.length > 0
			)
		) {
			return;
		}
		return (
			<div className="workshift-container bio-subsection-wrapper">
				<div className="title is-inline-block">
					<Icon icon="fa-regular fa-clock" />
					Preferred Workshift
				</div>
				<div className="workshift-tags">
					<Workshifts showOnlyOptimalWorkshift candidate={props.candidate} />
					<span className="add-margin-left">
						{moment.tz.guess().replace('-', ' ')}
					</span>
				</div>
			</div>
		);
	};

	const candidate = props.candidate;
	const config = candidate.candidateProfile;
	const showBio = props.candidate.aboutMe || (config && config.showAboutMe);

	return (
		<>
			<div id="header" className="bio-wrapper is-public-profile" ref={bioRef}>
				<div className="bio portal is-public-profile">
					<div className="bio-left">
						<CandidateProfileImage candidate={props.candidate} />
						<div className="name-container is-hidden-desktop">
							<div className="name is-inline-block">
								<h1>{props.candidate.name}</h1>
							</div>
						</div>
						<AnchorIcons
							candidate={props.candidate}
							handleAnchorClick={handleAnchorClick}
							activeSection={activeSection}
						/>
					</div>
					<div className="bio-description-wrapper">
						<div className="name-container desktop-only">
							<div className="name is-inline-block">
								<h1>{props.candidate.name}</h1>
							</div>
						</div>
						{showBio ? (
							<div className="aboutMe-container bio-subsection-wrapper">
								<div className="aboutMe">
									{props.candidate.aboutMe ? (
										<>
											<h3 className="title is-5">
												<Icon icon="fa-regular fa-user" />
												Bio
											</h3>
											<p>{props.candidate.aboutMe}</p>
										</>
									) : null}
								</div>
							</div>
						) : null}
						{props.candidate.topSkills.length > 0 && (
							<div className="top-skills-container bio-subsection-wrapper">
								<div className="title is-inline-block">
									<Icon icon="fa-solid fa-desktop" />
									Top Technologies
								</div>
								{props.candidate.topSkills.length > 0 ? (
									<CandidateProfileTopSkills
										skills={props.candidate.topSkills}
									/>
								) : (
									<div>
										<p>
											There are no skills listed in this candidate's profile
											yet.
										</p>
									</div>
								)}
							</div>
						)}
						<div className="is-flex">
							{renderCandidateWorkshift()}
							<div className="location-container bio-subsection-wrapper">
								<div className="title is-inline-block">
									<Icon icon="fa-solid fa-map-marker-alt" />
									Location
								</div>
								<div>
									<span>
										{props.candidate.country
											? props.candidate.country.name
											: null}
									</span>
								</div>
							</div>
						</div>
						{props.candidate.candidateRepos.length > 0 && (
							<CandidatePublicProfileRepos
								repos={props.candidate.candidateRepos}
							/>
						)}
					</div>
				</div>
			</div>
			<CandidatePublicProfileShortBio
				candidate={candidate}
				ref={shortBioRef}
				handleAnchorClick={(e, sectionId) =>
					handleAnchorClick(e, sectionId, isMobile() ? 252 : 200)
				}
				activeSection={activeSection}
			/>
		</>
	);
}

export { CandidatePublicProfileBio };
